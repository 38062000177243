import React from "react"
import { Link } from "gatsby"

import styles from "../styles/requerimento-submetido.module.scss"

import Layout from "../components/Layout"
import Button from "../components/Button"

const RequerimentoSubmetido = () => {
  return (
    <Layout>
      <div className={styles.wrapper}>
        <div className={styles.success}>
          <h1>Requerimento submetido!</h1>
          <p>Será contactado em breve por um elemento da Junta de Freguesia.</p>
          <Link to="/">
            <Button>Regressar à página inicial</Button>
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export default RequerimentoSubmetido
